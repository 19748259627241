import { FC } from 'react'
import { Box, Flex } from '@/components/atoms/Grid'
import styled from 'styled-components'
import Link from '@/components/atoms/link'
import { PictureWithPlaceholder } from '@/components/atoms/picture'
import { ImageQuality } from '@/utils/imageExtension'
import { useTrackBanners } from 'hooks/useTrackBanners'
import {
  BannerDescriptionType,
  BannerPageLocationType,
  BannersPositionType
} from '../bannersLayout'
import { MHubBannerProps } from '@/providers/mhub/useMhubTrackBanner'
import useDevice from 'hooks/useDevice'

type HomeBannerProps = {
  imgSrc: string
  imgSrcMobile: string
  primaryUrl: string
  secondaryUrl?: string
  priority: boolean
  id: string
  title: string
} & Rest

const HomeMainBanner: FC<HomeBannerProps> = (props) => {
  const {
    imgSrc,
    imgSrcMobile,
    primaryUrl,
    secondaryUrl,
    priority,
    id,
    title,
    ...rest
  } = props

  const { isDesktop } = useDevice()

  const imgSrcDesktop = isDesktop ? imgSrc : imgSrcMobile || imgSrc

  const trackBanner: MHubBannerProps = {
    id,
    position: BannersPositionType.Top,
    title: title,
    primaryUrl: primaryUrl,
    imgSrc: imgSrcDesktop,
    sortOrder: rest.sortOrder,
    description: BannerDescriptionType.Carousel,
    page: BannerPageLocationType.Home
  }
  const { observe, pushShowBanners } = useTrackBanners(trackBanner)
  const setTrackBanner = () => {
    pushShowBanners(trackBanner)
  }
  return (
    <StyledWrapper {...rest} data-cy="homepageMainBanner">
      <Box ref={observe}>
        <PictureWithPlaceholder
          src={imgSrc}
          desktop={imgSrc}
          tablet={imgSrc}
          mobile={imgSrcMobile}
          ratio={{ desktop: 476 / 1400, tablet: 476 / 1400, mobile: 824 / 768 }}
          quality={ImageQuality.MediumHigh}
          preload={priority}
          lazy={!priority}
        />
      </Box>

      {secondaryUrl ? (
        <LinksWrapper onClick={setTrackBanner}>
          <Link href={primaryUrl} />
          <Link href={secondaryUrl} />
        </LinksWrapper>
      ) : (
        <LinksWrapper onClick={setTrackBanner}>
          <Link href={primaryUrl} />
        </LinksWrapper>
      )}
    </StyledWrapper>
  )
}

export default HomeMainBanner

const StyledWrapper = styled(Flex)`
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
  width: 100%;
`

const LinksWrapper = styled(Flex)`
  flex-direction: row;
  justify-content: space-evenly;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
`
