import { FC } from 'react'
import { SvgProps } from './svgProps'

const ReturnIconPlain: FC<SvgProps> = ({ width, height, color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width || 24}
    height={height || 22}
    viewBox="0 0 24 22"
  >
    <g fill="none" fillRule="evenodd">
      <g>
        <g>
          <path
            d="M0 0H22V22H0z"
            transform="translate(-316 -109) translate(317 109)"
          />
          <g stroke={color || '#000'} strokeLinecap="round" strokeWidth="1.5">
            <path
              d="M19.067 9.533C19.067 4.268 14.798 0 9.533 0 4.268 0 0 4.268 0 9.533s4.268 9.534 9.533 9.534h0c2.705 0 5.146-1.126 6.881-2.935"
              transform="translate(-316 -109) translate(317 109) translate(.733 1.467)"
            />
            <path
              d="M17.6 8.433L19.067 9.533 20.533 8.433"
              transform="translate(-316 -109) translate(317 109) translate(.733 1.467)"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default ReturnIconPlain
