import { SlideItemsCount } from '@/components/organisms/productsSlider/horizontalTitledProductsSlider'
import { Flex } from '@/components/atoms/Grid'
import { ProductSlide } from '@/components/organisms/productsSlider'
import Link from '@/components/atoms/link'
import useTranslation from 'next-translate/useTranslation'
import { Manufacturer } from '@/network/graphql.g'
import { FC, useEffect, useState } from 'react'
import BaseSlider from '@/components/organisms/baseSlider'
import { PictureWithPlaceholder } from '../atoms/picture'
import { useTheme } from 'styled-components'

type TopManufacturerSliderProps = {
  manufacturers: Manufacturer[]
  slidesToShow?: SlideItemsCount
  slidesPerGroup?: SlideItemsCount
  hideTitle?: boolean
} & Rest

const TopManufacturerSlider: FC<TopManufacturerSliderProps> = ({
  manufacturers,
  slidesPerGroup = { desktop: 1, tablet: 1, mobile: 1 },
  slidesToShow = { desktop: 7.5, tablet: 4.5, mobile: 3.5 },
  hideTitle = false,
  ...rest
}) => {
  const theme = useTheme()
  const { t } = useTranslation('common')
  const [client, setClient] = useState<boolean>(false)
  useEffect(() => {
    setClient(true)
  }, [])
  const height = 45
  return (
    <BaseSlider
      title={hideTitle ? '' : t('HomePage.Sections.TopManufacturers.title')}
      slidesToShow={slidesToShow}
      slidesPerGroup={slidesPerGroup}
      showArrows={false}
      {...rest}
    >
      {manufacturers &&
        manufacturers.map((manufacturer) => {
          return (
            <ProductSlide key={manufacturer.id}>
              <Link href={manufacturer.link}>
                <Flex
                  m={20}
                  height={height}
                  alignItems="center"
                  display="block"
                >
                  <PictureWithPlaceholder
                    src={manufacturer.image}
                    ratio={height / 60}
                    style={{
                      objectFit: 'contain',
                      height,
                      backgroundColor: theme.colors.background
                    }}
                    disableBackground={!client}
                    transition={0.01}
                  />
                </Flex>
              </Link>
            </ProductSlide>
          )
        })}
    </BaseSlider>
  )
}

export default TopManufacturerSlider
