import { FC } from 'react'
import { SvgProps } from './svgProps'

const ReturnIcon: FC<SvgProps> = ({ width, height, color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width || 16}
    height={height || 16}
    viewBox="0 0 16 16"
  >
    <g fill="none" fillRule="evenodd">
      <g>
        <g>
          <path
            d="M0 0H16V16H0z"
            transform="translate(-661 -38) translate(661 38)"
          />
          <g>
            <path
              stroke={color || '#FFF'}
              strokeLinecap="round"
              d="M13.867 6.933C13.867 3.104 10.763 0 6.933 0 3.104 0 0 3.104 0 6.933c0 3.83 3.104 6.934 6.933 6.934h0c1.967 0 3.743-.82 5.004-2.135"
              transform="translate(-661 -38) translate(661 38) translate(.533 1.067)"
            />
            <path
              stroke={color || '#FFF'}
              strokeLinecap="round"
              d="M12.8 6.133L13.867 6.933 14.933 6.133"
              transform="translate(-661 -38) translate(661 38) translate(.533 1.067)"
            />
            <path
              fill={color || '#FFF'}
              d="M5.785 6.295c.193 0 .365.048.516.142.15.095.268.231.353.41.085.178.127.392.127.64 0 .238-.052.458-.155.66-.103.2-.252.36-.447.477-.195.117-.425.176-.689.176-.454 0-.793-.151-1.019-.454-.225-.303-.338-.722-.338-1.258 0-.402.064-.756.19-1.06.128-.305.31-.541.545-.71.235-.167.512-.251.83-.251.312 0 .58.078.803.236l-.325.551c-.149-.09-.301-.136-.457-.136-.176 0-.32.073-.432.22-.112.147-.18.36-.203.64.196-.188.43-.283.7-.283zm-.29 1.849c.129 0 .225-.057.287-.171.063-.114.094-.272.094-.475 0-.21-.028-.358-.086-.444-.058-.086-.15-.129-.28-.129-.07 0-.144.019-.218.056-.075.036-.144.09-.208.16 0 .248.012.445.038.59.025.146.067.25.127.316.059.064.141.097.246.097zm2.897-3.077c.433 0 .765.16.996.483.23.322.345.782.345 1.38 0 .6-.115 1.06-.345 1.384-.23.324-.563.486-.996.486-.434 0-.767-.162-.999-.486-.232-.324-.348-.785-.348-1.383 0-.602.116-1.064.348-1.384.232-.32.565-.48.999-.48zm0 .682c-.105 0-.188.036-.25.108-.06.072-.104.192-.131.362-.027.17-.041.407-.041.712 0 .308.014.547.04.716.028.17.072.291.133.363.06.072.144.107.249.107.105 0 .187-.034.246-.102.06-.068.103-.187.13-.357.027-.17.04-.412.04-.727s-.013-.558-.04-.728c-.027-.17-.07-.287-.13-.354-.06-.066-.141-.1-.246-.1z"
              transform="translate(-661 -38) translate(661 38) translate(.533 1.067)"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default ReturnIcon
