import { SlideItemsCount } from '@/components/organisms/productsSlider/horizontalTitledProductsSlider'
import { breakpoints } from '@/theme/breakpoints'
import { Box } from '@/components/atoms/Grid'
import ProductsSlider from '@/components/organisms/productsSlider'
import styled from 'styled-components'
import { ArrowsPosition } from '@/components/organisms/productsSlider/productsSlider'
import { H1 } from '@/components/atoms/typography'
import { validFor } from '@/theme/validFor'
import { FC } from 'react'

type BaseSliderProps = {
  slidesToShow: SlideItemsCount
  slidesPerGroup: SlideItemsCount
  arrowsTopPercent?: number
  title?: string
  showArrows?: boolean
} & Rest

const BaseSlider: FC<BaseSliderProps> = ({
  title,
  slidesToShow,
  slidesPerGroup,
  children,
  arrowsTopPercent,
  showArrows = true,
  ...rest
}) => (
  <Box {...rest}>
    {title && (
      <StyledBox>
        <StyledH1>{title}</StyledH1>
      </StyledBox>
    )}
    <StyledProductsSlider
      showArrows={showArrows}
      arrowsPosition={
        showArrows ? ArrowsPosition.Outside : ArrowsPosition.Inside
      }
      showPagination={false}
      infinite={true}
      arrowsTopPercent={arrowsTopPercent}
      autoPlay={false}
      breakpoints={{
        [breakpoints.desktop.min]: {
          slidesPerView: slidesToShow.desktop,
          slidesPerGroup: slidesPerGroup.desktop
        },
        [breakpoints.tablet.min]: {
          slidesPerView: slidesToShow.tablet,
          slidesPerGroup: slidesPerGroup.tablet
        },
        [breakpoints.mobile.min]: {
          slidesPerView: slidesToShow.mobile,
          slidesPerGroup: slidesPerGroup.mobile
        }
      }}
    >
      {children}
    </StyledProductsSlider>
  </Box>
)

export default BaseSlider

const StyledProductsSlider = styled(ProductsSlider)`
  width: 100%;
`
const StyledBox = styled(Box)`
  text-align: left;
  width: 100%;
  margin-top: 30px;
  ${validFor.tablet`
     margin-top: 20px;
  `}
  ${validFor.mobile`
     margin-top: 15px;
  `}
`

const StyledH1 = styled(H1)`
  ${validFor.tablet`
    font-size: 30px;
  `}
  ${validFor.mobile`
    font-size: 20px;
  `}
`
