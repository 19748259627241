import { FC, useEffect, useState } from 'react'
import { Flex } from '@/components/atoms/Grid'
import { HomePageBanners } from '@/components/organisms/home'
import PageContentWrapper from '@/components/organisms/pageContentWrapper'
import { HomeBanner, Manufacturer } from '@/network/graphql.g'
import { requestThrow401 } from '@/utils/graphQl'
import { notifyError, notifySuccess } from '@/core/toast'
import { withBaseServerSideProps } from '@/HOC/withBaseServerSideProps'
import { useMhub } from '@/providers/mhubProvider'
import dynamic from 'next/dynamic'
import { useSpeedCurve } from '@/core/speedCurve/useSpeedCurve'
import { GetServerSidePropsContext } from 'next'
import { getMainData } from '@/utils/mainDataHelper'
import { CacheType } from '@/utils/cache/pageDataCache'
import useDevice from '../hooks/useDevice'
import { useScrollCondition } from '../hooks/useScroll'
import { SeoHomePage } from '@/utils/seoData'

const DynamicHomePageProducts = dynamic(
  () => import('@/components/organisms/home/homePageProducts'),
  {
    ssr: false
  }
)

type HomePageProps = {
  sliderBanners: HomeBanner[]
  smallBanners: HomeBanner[]
  topManufacturers: Manufacturer[]
  successMessge: string
  errorMessge: string
}

const HomePage: FC<HomePageProps> = ({
  sliderBanners,
  smallBanners,
  topManufacturers,
  successMessge,
  errorMessge
}) => {
  useEffect(() => {
    successMessge && notifySuccess(successMessge)
    errorMessge && notifyError(errorMessge)
  }, [])

  const { pushHomePage } = useMhub()
  useEffect(() => {
    pushHomePage()
  }, [])

  useSpeedCurve({
    label: 'Home'
  })

  const { isDesktop, isTablet } = useDevice()
  const [isScrolledAndStay, setIsScrolledAndStay] = useState<boolean>(false)
  const screenOffset = isDesktop || isTablet ? 100 : 400
  const isScrolledPastThreshold = useScrollCondition(
    (scroll) => scroll > screenOffset
  )

  useEffect(() => {
    {
      if (isTablet) {
        const timer = setTimeout(() => {
          setIsScrolledAndStay(true)
        }, 4000)
        return () => clearTimeout(timer)
      } else {
        if (isScrolledPastThreshold) {
          setIsScrolledAndStay(true)
        }
      }
    }
  }, [isScrolledAndStay, isScrolledPastThreshold, isTablet])

  return (
    <PageContentWrapper>
      <SeoHomePage></SeoHomePage>

      <Flex width="100%" flexDirection="column">
        <HomePageBanners
          bannerElements={sliderBanners}
          promoBanners={smallBanners}
          topManufacturerBanners={topManufacturers}
          data-cy="homepageBanners"
        />
        {isScrolledAndStay ? (
          <DynamicHomePageProducts
            slideItemsCount={{ desktop: 5, tablet: 3, mobile: 2 }}
          />
        ) : null}
      </Flex>
    </PageContentWrapper>
  )
}

const getCommonProps = (
  context: GetServerSidePropsContext
): { successMessge: string; errorMessge: string } => ({
  successMessge: context.query?.success?.toString() ?? null,
  errorMessge: context.query?.error?.toString() ?? null
})

export const getServerSideProps = withBaseServerSideProps(
  async ({
    context,
    sdk,
    withMainNavigation,
    withStore
  }): Promise<GetServerSideProps<HomePageProps>> => {
    const { success, response } = await requestThrow401({
      method: sdk.getHomePageData,
      variables: {
        manufacturersLimit: 20,
        withMainNavigation,
        withStore
      },

      successPredicate: (response) =>
        !!(
          response?.topManufacturers &&
          response?.homeBannersForHpSlider &&
          response?.homeBannersForHpSmall
        ),
      requestHeaders: {
        'x-cachable': '1'
      }
    })

    if (!success) {
      return {
        notFound: true
      }
    }
    return {
      props: {
        ...{
          mainData: getMainData(response),
          seoTags: response?.seoTags,
          sliderBanners: response?.homeBannersForHpSlider,
          smallBanners: response?.homeBannersForHpSmall,
          topManufacturers: response?.topManufacturers as Manufacturer[]
        },
        ...getCommonProps(context)
      }
    }
  },
  { cacheType: CacheType.Homepage, getCommonProps }
)
export default HomePage
