import { FC } from 'react'
import { SvgProps } from './svgProps'

const DiscountBigIcon: FC<SvgProps> = ({ width, height, color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width || 30}
    height={height || 30}
    viewBox="0 0 30 30"
  >
    <g fill="none" fillRule="evenodd">
      <g>
        <g>
          <path
            d="M0 0H30V30H0z"
            transform="translate(-142 -106) translate(142 106)"
          />
          <g>
            <path
              stroke={color || '#000'}
              strokeLinecap="round"
              strokeWidth="2"
              d="M14.172 26.625l-2.964 1.348c-1.005.457-2.191.012-2.648-.994-.019-.04-.036-.083-.052-.125L7.205 23.38c-.101-.27-.315-.484-.585-.585l-3.474-1.303c-1.034-.388-1.558-1.54-1.17-2.575.015-.042.033-.084.051-.125l1.536-3.378c.12-.263.12-.565 0-.828l-1.536-3.378c-.457-1.005-.012-2.191.994-2.648.04-.019.083-.036.125-.052L6.62 7.205c.27-.101.484-.315.585-.585l1.303-3.474c.388-1.034 1.54-1.558 2.575-1.17.042.015.084.033.125.051l3.378 1.536c.263.12.565.12.828 0l3.378-1.536c1.005-.457 2.191-.012 2.648.994.019.04.036.083.052.125l1.303 3.474c.101.27.315.484.585.585l3.474 1.303c1.034.388 1.558 1.54 1.17 2.575-.015.042-.033.084-.051.125l-1.536 3.378c-.12.263-.12.565 0 .828l1.536 3.378c.457 1.005.012 2.191-.994 2.648-.04.019-.083.036-.125.052l-3.474 1.303c-.27.101-.484.315-.585.585l-1.303 3.474c-.388 1.034-1.54 1.558-2.575 1.17-.042-.015-.084-.033-.125-.051l-2.964-1.348c-.526-.239-1.13-.239-1.656 0z"
              transform="translate(-142 -106) translate(142 106)"
            />
            <path
              fill={color || '#000'}
              d="M18 16c1.105 0 2 .895 2 2s-.895 2-2 2-2-.895-2-2 .895-2 2-2zm.707-4.707c.39.39.39 1.024 0 1.414l-6 6c-.39.39-1.024.39-1.414 0-.39-.39-.39-1.024 0-1.414l6-6c.39-.39 1.024-.39 1.414 0zM12 10c1.105 0 2 .895 2 2s-.895 2-2 2-2-.895-2-2 .895-2 2-2z"
              transform="translate(-142 -106) translate(142 106)"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default DiscountBigIcon
