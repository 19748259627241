import { FC } from 'react'
import { SvgProps } from './svgProps'

const FreeShippingSmallIcon: FC<SvgProps> = ({ width, height, color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width || 16}
    height={height || 16}
    viewBox="0 0 16 16"
  >
    <g fill="none" fillRule="evenodd">
      <g>
        <g>
          <path
            d="M0 0H16V16H0z"
            transform="translate(-634 -38) translate(634 38)"
          />
          <g
            stroke={color || '#FFF'}
            strokeLinecap="round"
            transform="translate(-634 -38) translate(634 38) translate(1.067 1.067)"
          >
            <circle cx="3.2" cy="12.267" r="1.6" />
            <circle cx="10.667" cy="12.267" r="1.6" />
            <path d="M10.133 4.267h1.313c.818 0 1.553.498 1.857 1.257l.42 1.052c.095.236.144.488.144.742v2.815c0 .884-.717 1.6-1.6 1.6h0M0 8.533V3c0-1.657 1.343-3 3-3h4.133c1.657 0 3 1.343 3 3v2.533c0 1.657-1.343 3-3 3H0h0v1.6c0 .884.716 1.6 1.6 1.6h0M4.8 11.733L9.067 11.733" />
            <path d="M3.733 4.533l.726.726c.039.039.102.039.141 0l.004-.004 2.33-2.588h0" />
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default FreeShippingSmallIcon
